import * as React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ContentWrapper from "../../components/wrappers/contentWrapper"
import ContentPart from "../../components/services/template/content"
import FireExtingHero from "../../components/heros/fireExtinguisherHero"

const TitleText = `
	Fire Extinguisher Inspections
`
const FireExtingInspectionsPage = () => (
	<Layout>
		<Seo title={TitleText} description="The best known and commonly handled type of fire protection equipment is the fire extinguisher. Extinguishers are often the first line of defense in a fire emergency. Peoples’ dependence on fire extinguishers requires they are maintained to ensure they work during an emergency." />

		<FireExtingHero />

		<ContentWrapper>

			<ContentPart>
				<p>The best known and commonly handled type of fire protection equipment is the fire extinguisher. Extinguishers are often the first line of defense in a fire emergency. Peoples’ dependence on fire extinguishers requires they are maintained to ensure they work during an emergency.</p>
				<p>Local and provincial regulations set standards for fire extinguisher location, installation, inspection frequency, and maintenance schedule. Fire extinguisher inspections occur at specified intervals. We can ensure you and your staff can locate your fire extinguishers, proper usage and stay updated with scheduled inspections. Using the required training and credentials, we conduct annual fire extinguisher inspections.</p>
				<p>
					<Link to="/contact/" title="fire extinguisher services contact page">Contact us today for help with your fire extinguishers.</Link>
				</p>

			</ContentPart>
		</ContentWrapper>
	</Layout>
)

export default FireExtingInspectionsPage